<template>
  <div class="shadow-md relative rounded-2xl bg-card-bg flex flex-col">
    <div
      class="p-3 panel-head bg-primary-300 font-bold text-white-text flex gap-3 justify-between items-center rounded-t-2xl"
    >
      Workflow
    </div>
    <div
      v-if="loadingWorkflows"
      class="max-auto flex flex-col gap-5 justify-center items-center w-full h-80 rounded-lg ring-2-gray-500"
    >
      <Loader />
      Loading Workflows...
    </div>
    <!-- <case-workflow /> -->
    <div v-else>
      <div
        class="justify-between flex-grow max-h-full overflow-y-auto"
        v-if="this.getWorkflowId"
      >
        <div class="flex h-full gap-4 p-4 flex-1 overflow-y-auto">
          <div
            v-if="currentComponent.name != 'createTriggerActions'"
            class="w-2/12 rounded-lg self-start bg-white shadow flex flex-col gap-x-4 overflow-y-auto scroll-bar"
          >
            <div
              @click="handleCurrentTab(nav)"
              v-for="(nav, idx) in navigation"
              :key="idx"
              class="px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3 cursor-pointer"
              :class="{
                'bg-primary-300 hover:bg-primary-300 text-white-text': currentTab === nav.nav,
                'hover:bg-gray-100 hover:text-base-content-600': currentTab !== nav.nav,
                'border-t border-gray-400 border-0': idx != 0,
              }"
            >
              <!-- :style="{borderColor: currentTab === nav.nav ? '#cdcdcd': '' }" -->
              <font-awesome-icon :icon="[nav.iconPrefix || 'fa', nav.icon]" />
              {{ nav.title }}
              <!-- <font-awesome-icon class="text-gray-500 absolute right-2" v-if="currentTab === nav.nav" icon="arrow-right" /> -->
            </div>
          </div>
          <div
            v-if="currentTab"
            :class="
              isTriggersRoute
                ? 'flex-1 relative'
                : 'flex-1 relative bg-card-bg rounded-md shadow'
            "
          >
            <div
              v-if="!isTriggersRoute"
              class="px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-white-text text-lg font-medium"
              :style="`max-height: 20vh;`"
            >
              <span class="font-bold">{{ TabLabel }} </span>
              <!-- {{ packageData.package_name }} -->
            </div>

            <div :class="isTriggersRoute ? '' : 'p-3 flex pb-4'">
              <component v-bind:is="currentComponent"> </component>
              <!-- <div>helloooo</div> -->
            </div>
          </div>
         
        </div>
        <div class="flex p-3 w-full items-center justify-end" v-if="!isViewMode && this.getWorkflowId">
            <div class="flex rounded-lg justify-end">
            <v-button
              text="Replace Workflow"
              class="py-2"
              type="primary"
              @click="handleReplaceWorkflow"
            />
          </div>
          </div>
      </div>
      <div
        class="flex justify-center rounded-lg bg-white h-96 p-3"
        v-if="!this.getWorkflowId && !isViewMode"
      >
        <!-- <div
        class="flex min-h-full min-w-full justify-center items-center rounded-md"
      >
        <loader class="my-5" height="32px" width="32px" :loading="true" />
        Loading
      </div> -->
        <div
          class="flex gap-2 w-full items-center justify-center rounded-lg bg-white"
        >
          <div class="flex rounded-lg">
            <v-button
              text="Add New"
              class="py-2"
              type="primary"
              @click="handleAddDefault"
            />
          </div>
        </div>
      </div>
      <div v-else-if="isViewMode && !this.getWorkflowId">
        <div
          class="w-full flex justify-center items-center flex-1 rounded-lg bg-white h-full"
        >
          <Empty
            text="There is no workflow attached to this package."
            classList="text-lg"
          />
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <!-- <RecursiveStatusLis/>
        <TriggersAndActions :showSubHeader="false"/> -->
        <modalContent
          ref="workflowModal"
          name="workflowModal"
          title="Add Workflow"
          :max-width="400"
          :loadingGlobal="loadingWorkflowsModal"
          :show-footer="false"
          class="modal-class"
        >
          <template #content>
            <div v-for="workflow in workflows" :key="workflow.id" class="h-12">
              <div class="flex p-1 justify-between w-full">
                <div class="w-10/12 truncate" :title="workflow.name">{{ workflow.name }}</div>
                <v-button
                  text="Add"
                  type="primary"
                  @click="handleAddWorkflow(workflow)"
                />
              </div>
              <div class="border-t border-gray-400 p-1"></div>
            </div>
          </template>
        </modalContent>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@shared/components/button";
import Loader from "@/components/loader";
import {
  getAllCaseWorkflows,
  linkPackageWorkflow,
  getPackageWorkflow,
} from "@shared/workflow/service.js";
import modalContent from "@/components/modal-content";
import CreateWorkflow from "@shared/workflow/components/create-workflow.vue";
import StateTransition from "@shared/workflow/components/state-transition.vue";
import TriggersActions from "@shared/workflow/components/triggersActions.vue";
import CreateTriggerActions from "@shared/workflow/components/createTriggers.vue";
import Empty from "@shared/empty";

export default {
  name: "package-content-workflow",
  data() {
    return {
      loadingWorkflows: false,
      workflowId: "",
      customBreadCrumbs: [],
      currentTab: "workflow",
      addDefault: false,
      workflows: [],
      loadingWorkflowsModal: true,
      comp: "case-status",
      screeningMenuWrapper: {
        workflow: CreateWorkflow,
        stateTransitions: StateTransition,
        triggerAndActions: TriggersActions,
        createTriggerActions: CreateTriggerActions,
      },
      navigation: [
        {
          title: "Workflow Details",
          nav: "workflow",
          icon: "file-lines",
          subnav: false,
          iconPrefix: "far",
        },
        {
          title: "State Transition",
          nav: "stateTransitions",
          icon: "file-lines",
          subnav: false,
          iconPrefix: "far",
        },
        {
          title: "Triggers & Actions",
          nav: "triggerAndActions",
          icon: "file-lines",
          subnav: false,
          iconPrefix: "far",
        },
      ],
    };
  },
  components: {
    "v-button": Button,
    // RecursiveStatusList,
    // TriggersAndActions,
    //caseStatus,
    modalContent,
    CreateWorkflow,
    StateTransition,
    TriggersActions,
    CreateTriggerActions,
    Loader,
    Empty,
  },
  computed: {
    TabLabel() {
      if (this.currentTab === "workflow") {
        return "Create Workflow";
      } else if (this.currentTab === "stateTransitions") {
        return "Status";
      } else {
        return "Trigger And Action";
      }
    },
    currentComponent() {
      return this.screeningMenuWrapper[this.currentTab];
    },
    isTriggersRoute() {
      return this.$route.params.workflowTool == "triggerAndActions" ||
        this.$route.params.workflowTool == "createTriggerActions"
        ? true
        : false;
    },
    getWorkflowId() {
      return this.$route.params?.workflowId;
    },
    isViewMode() {
      return this.$route.params.action == "view" ? true : false;
    },
  },
  async mounted() {
    await this.getPackageWorkflows();
  },
  methods: {
    async getPackageWorkflows() {
      try {
        this.loadingWorkflows = true;
        let workflowData = await getPackageWorkflow(
          "check",
          this.$route.params.id
        );
        workflowData = workflowData.filter((el) => el.is_active);
        if (workflowData && workflowData.length && workflowData[0].id) {
          this.$router.push({
            params: { workflowId: workflowData[0].id, type: "check" },
          });
        }
        this.loadingWorkflows = false;
      } catch (err) {
        if (err.response.request.status != 400) {
          this.$toast.error("Something went wrong.");
        }
        this.loadingWorkflows = false;
      }
    },
    setBredCrumbs(crumb) {
      this.customBreadCrumbs = [{ name: crumb }];
    },
    async handleAddWorkflow(workflow) {
      const response = await linkPackageWorkflow("check", workflow.id, this.$route.params.id);
      if(response?.data?.status) {
          this.$toast.success(response?.data?.status_message);
        }
      this.getPackageWorkflows()
      // this.$router.push({
      //   name: "Workflow Tools",
      //   params: {
      //     workflowTool: "workflowDetails",
      //     ro: true,
      //     workflowId: workflow.id,
      //   },
      // });
    },
    handleCurrentTab(tab) {
      this.$router.push({
        params: { workflowTool: tab.nav },
      });
      if (this.currentTab === tab.nav) return;
      this.currentTab = tab.nav;

      // this.setBredCrumbs(this.currentTab);
      // this.$router.push({
      //     name: "Workflow Tools",
      //     params: { workflowTool: this.currentTab },
      // });
    },
    handleReplaceWorkflow() {
            this.handleAddDefault()
        },
    async handleAddDefault() {
      this.addDefault = true;
      this.loadingWorkflowsModal = true;
      this.$refs["workflowModal"].showModal();
      this.workflows = await getAllCaseWorkflows("check");
      this.workflows.forEach((res) => {
        res.loading = false;
      });
      this.loadingWorkflowsModal = false;
      this.addDefault = false;
    },
  },
  // components: {
  //     "v-button": Button,
  // },
};
</script>
